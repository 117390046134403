
/**
 * 对数字进行千分位（每三位数字添加逗号分隔
 * @param {*} number 
 * @returns 
 */
export function formatNumberWith(number) {
    return new Intl.NumberFormat().format(number);
}

